<template>
  <div>
  
    <div
      class="
      flex
      xs12"
    >
      <va-card title="Edit Template">
        <template slot="actions">
          <va-button
            :icon="'fa fa-chevron-left'"
            @click="$router.push({name:'email'})"
          >
            Back to templates
          </va-button>
          <va-button
            :icon="'fa fa-info'"
            @click="showAvailableVariables"
          >
            Check Available Variables
          </va-button>
          <va-button
            :icon="sending ? 'fa fa-spinner fa-spin' : 'fa fa-envelope-o'"
            @click="testEmail(false)"
          >
            Test Raw Message
          </va-button>
          <va-button
            :icon="sending ? 'fa fa-spinner fa-spin' : 'fa fa-envelope-o'"
            @click="testEmail(true)"
          >
            Send Template
          </va-button>
          <va-button
            :icon="saving ? 'fa fa-spinner fa-spin' : 'fa fa-save'"
            @click="saveDesign"
          />
        </template>
        <va-input
          label="Subject"
          placeholder="Subject line of the email"
          v-model="template.subject"
        />
        <EmailEditor
          ref="emailEditor"
          @load="editorLoaded"
          @ready="editorReady"
        />
      </va-card>
    </div>
  </div>
</template>
<script>
import {EmailEditor} from 'vue-email-editor';

import firebase from 'firebase';
export default {
  components: {
    EmailEditor,
  },
  data: function() {
    return {
      sending: false,
      loading: true,
      saving: false,
      template: {},
    };
  },
  beforeCreate() {
    if(!this.$route.params.id) {
      this.$swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'We couldn\'t find that template!',
      });
      this.$router.push({name:'email'});
      return;
    }
  },
  created() {
    if(this.$route.params.id){
      firebase.firestore().collection('emailTemplates').doc(this.$route.params.id).get().then((snapshot)=>{
        this.template = snapshot.data();

        if (this.template.templateData) {
          this.$refs.emailEditor.editor.loadDesign(JSON.parse(this.template.templateData));
        }
      });
    }
  },
  methods: {
    editorLoaded() {
      console.log('editorLoaded');
    },
    // called when the editor has finished loading
    editorReady() {
      console.log('editorReady');
    },
    saveDesign() {
      this.saving = true;
      this.$refs.emailEditor.editor.exportHtml((data) => {
        firebase.firestore().collection('emailTemplates').doc(this.$route.params.id).update({
          templateData: JSON.stringify(data.design),
          html: data.html,
          subject: this.template.subject,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        }).then(()=>{
          this.saving = false;
        });
        // firebase.firestore().collection('emailTemplates').doc(this.$route.params.id).update({
        //   html: data,
        // });
      });
    },
    /**
     * @name showAvailableVariables
     * @description Shows a list of available variables for this email template
    */
    showAvailableVariables(){
      const variables = this.template?.variables || [];
      let contentW = '<p>Here is a list of variables you can use in this email template: <br> <br> <ul>' + variables.map((variable) => {
          return '<li><b>' + variable.name + '</b>: ' + variable.desc + '</li>';
      }).join('') + '</ul></p>';
      let html = variables.length > 0 ? contentW : '<p>There are no variables available for this email template.</p>';
      this.$swal.fire({
        title: 'Available Variables',
        html: html,
        icon: 'info',
      });
    },
    exportHtml() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        console.log('exportHtml', data);
      });
    },

    async testEmail(sendTemplate) {
      const emailAddress = await this.$swal.fire({
        title: 'Email Certificate',
        inputLabel: 'Who do you want to email it to?',
        input: 'text',
        inputValue: firebase.auth().currentUser.email,
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'Can\'t email a blank email address, dummy';
          }
        },
      });
      if (emailAddress.value) {
        this.sending = true;

        this.$refs.emailEditor.editor.exportHtml((data)=>{
          const sendParams = {to: emailAddress.value,
            message: {
              subject: this.template.subject,
              html: data.html,
            },
          };
          if (sendTemplate) {
            delete sendParams.message;
            sendParams.template = {
              name: this.$route.params.id,
            };
          }


          firebase.firestore().collection('email').add(sendParams).then(()=>{
            this.sending = false;
            this.$swal.fire({
              icon: 'success',
              title: 'Email Sent!',
              timer: 1500,
            });
          });
        });
      }
    },
  },
  computed: {
  },
};
</script>
<style >
  iframe {
    min-height: 1000px !important;
  }
</style>
